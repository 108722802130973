body {
    font-family: 'Georgia', serif; /* Or choose a romantic font */
    display: flex;
    justify-content: center; /* Center items vertically */
    align-items: center; /* Center items horizontally */
    height: 100vh;
    margin: 0;
    background-color: #f8f0e8; /* A soft, warm color */
    padding: 20px;
}

.App {
    width: 100%;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.letter-container, .new-note-container {
    width: 100%;
    max-width: 800px;
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.letter-heading {
    text-align: left;
    margin-bottom: 20px;
}

.title-type {
    overflow: auto;
    white-space: normal;
    width: auto;
}

.letter-body p.intro {
    margin-bottom: 15px;
    text-align: left;
    white-space: normal;
    width: 100%;
}

