.tabs-container {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
}

.tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    border-bottom: 2px solid #ddd;
}

.tab {
    padding: 10px 20px;
    margin: 0 5px;
    border: none;
    background-color: #eee;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, border 0.3s;
    font-family: 'Georgia', serif;
    color: #333;
    border-radius: 5px 5px 0 0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.tab.active {
    background-color: #d4af37;  /* Gold color for active tab */
    color: #fff;
    font-weight: bold;
    border: 2px solid #d4af37;
    border-bottom: none;
    transition: background-color 0.3s, color 0.3s, border 0.3s;
}

.tab:hover {
    background-color: #ccc;
    color: #000;
}